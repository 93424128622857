/* General Styling */

.text-align-right {
  text-align: right;
}

.text-align-left {
  text-align: left;
}

.text-align-faq-right {
  text-align: right;
  padding-top: 10px;
  padding-right: 10px;
}

.text-align-left-bold {
  text-align: left;
  font-weight: 500;
}

.text-align-right-bold {
  text-align: right;
  font-weight: 500;
}

.result-container {
  background-color: rgb(241, 243, 249);
  padding: 10px;
  margin-top: 15px;
}

.faq-result-container {
  background-color: rgb(255, 255, 255);
  padding: 10px;
  margin-top: 15px;
}

.faq-answer {
  font-weight: normal;
}

.manufacturer-details-container {
  background: rgb(241, 243, 249);
  padding: 10px;
  margin-top: 25px;
}

.homepage-text-style {
  text-align: left;
  margin-top: 1;
  font-size: 17px;
  line-height: 2;
}

.text-with-tooltip {
  font-weight: 500;
  padding-bottom: 5px;
}

.text-with-tooltip button {
  margin: -20px;
}

.text-with-tooltip-heading {
  font-weight: 800;
  color: #0f1290;
}

/* Footer */

.footer-container {
  position: relative;
  bottom: 0;
  margin-bottom: 20px;
  margin-top: 30px;
  border-top: 1px solid #b1b4b6;
  padding-top: 10px;
}

.footer-elements {
  display: flex;
  gap: 2em;
  color: black;
  margin-top: 20px;
}
@media screen and (max-width: 1500px) {
  .footer-elements {
    flex-direction: column;
  }
}

/* Banners and Logo*/

.header-container {
  margin-bottom: 40px;
  margin-top: 20px;
}

.header-logo {
  width: 390px;
}

.footer-logo {
  width: 250px;
}
@media screen and (max-width: 1020px) {
  .header-logo {
    width: 260px;
  }
}
.phase-banner {
  padding-top: 10px;
  padding-bottom: 10px;
  border-bottom: 1px solid #b1b4b6;
}
@media print {
  .phase-banner-content {
    font-family: sans-serif;
  }
}
@media (min-width: 40.0625em) {
  .phase-banner-content {
    font-size: 16px;
    font-size: 1rem;
    line-height: 1.25;
  }
}
.phase-banner-content {
  font-family: GDS Transport, arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-weight: 400;
  font-size: 14px;
  font-size: 0.875rem;
  line-height: 1.14286;
  color: #0b0c0c;
  display: table;
  margin: 0;
}
@media print {
  .phase-banner-content {
    font-size: 14pt;
    line-height: 1.2;
    color: #000;
  }
}
.feedback-banner-tag {
  display: inline-block;
  outline: 2px solid rgba(0, 0, 0, 0);
  outline-offset: -2px;
  color: #0c2d4a;
  background-color: #bbd4ea;
  letter-spacing: 1px;
  text-decoration: none;
  font-family: GDS Transport, arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-weight: 400;
  font-size: 14px;
  font-size: 0.875rem;
  line-height: 1;
  padding: 5px 8px 4px;
}
@media print {
  .feedback-banner-tag {
    font-family: sans-serif;
  }
}
@media (min-width: 40.0625em) {
  .feedback-banner-tag {
    font-size: 16px;
    font-size: 1rem;
    line-height: 1;
  }
}
@media print {
  .feedback-banner-tag {
    font-size: 14pt;
    line-height: 1;
  }
}
.phase-banner-content-tag {
  margin-right: 10px;
}
.phase-banner-text {
  display: table-cell;
  vertical-align: middle;
}
.feedback-banner-link {
  font-family: GDS Transport, arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-decoration: underline;
}
@media print {
  .feedback-banner-link {
    font-family: sans-serif;
  }
}

/* Buttons */

button#submit-button {
  margin-top: 16px;
  margin-left: 11px;
  width: 0px;
}

button#back-button {
  margin-top: 16px;
  width: 64px;
}

button#advanced-search-button {
  color: #0f1290;
  text-decoration: underline;
}

button#search-submit-button {
  margin-top: 12px;
  float: left;
  margin-right: 2px;
  width: 64px;
}

button#clear-button {
  margin-top: 12px;
  margin-left: 16px;
  width: 64px;
}

/* Loading */

.loading {
  text-align: left;
  margin-top: 1;
  color: black;
  font-size: 19px;
  line-height: 1.8;
}

/* Advanced Search Button*/

.advanced-search-button {
  color: #0f1290;
  text-decoration: underline;
}

.loading-text {
  opacity: 0;
  animation: flash 2s linear infinite;
}

@keyframes flash {
  0%,
  50%,
  100% {
    opacity: 0;
  }
  25%,
  75% {
    opacity: 1;
  }
}

/* Paginate */

.pagination {
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 1rem 0;
}

.pagination-button {
  padding: 0.25rem 0.5rem;
  margin: 0;

  border: none;
  border-radius: 0.25rem;
  background-color: transparent;
  color: black;
  cursor: pointer;
}

@media (max-width: 768px) {
  .pagination-button {
    font-size: 0.8rem;
    padding: 0.1rem 0.5rem;
  }
}

.pagination-button:hover {
  color: #1976d2;
}

.pagination-button.active {
  color: #1976d2;
}
.prev-page {
  margin-right: 0;
}

.next-page {
  margin-left: 0;
}

/* Search Field */

.search-field {
  background: white;
  margin-top: 7px !important;
  margin-bottom: 5px !important;
  width: calc(100% - 75px);
}

.search-field-advanced {
  background: white;
  margin-top: 5px !important;
  margin-bottom: 10px !important;
  width: 100%;
}

.advanced-search .search-field {
  width: 100%;
}

.search-field-advanced .MuiOutlinedInput-root:hover fieldset {
  border-color: #0f1290;
}

.search-field-advanced label.Mui-focused {
  color: #00000099;
}

.search-field-advanced .MuiOutlinedInput-root:hover fieldset {
  border-color: #0f1290;
}

.search-field-advanced .MuiOutlinedInput-root.Mui-focused fieldset {
  border-color: #0f1290;
}

.search-field label.Mui-focused {
  color: #00000099;
}

.search-field .MuiInput-underline:after {
  border-bottom-color: #0f1290;
}

.search-field .MuiOutlinedInput-root:hover fieldset {
  border-color: #0f1290;
}

.search-field .MuiOutlinedInput-root.Mui-focused fieldset {
  border-color: #0f1290;
}

/* Select Search Field */

.form-control {
  width: calc(100%);
  margin-top: 7px !important;
  margin-bottom: 5px !important;
}

.form-control label.Mui-focused {
  color: #00000099;
}

.form-control .MuiInput-underline:after {
  border-bottom-color: #0f1290;
}

.form-control .MuiOutlinedInput-root:hover fieldset {
  border-color: #0f1290;
}

.form-control .MuiOutlinedInput-root.Mui-focused fieldset {
  border-color: #0f1290;
}

.select {
  background: white;
  width: calc(100%);
}
